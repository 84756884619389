.main{
    background-color: rgb(243,243,244);
    display: flex;
    flex-direction: column;
}

.bodyHeader{
    height: 40px;
    width: auto;
    background-color: white;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.bodyHeader__main{
    width: 85%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}


.bodyHeader p{
    margin: .5rem;
    font-size: .85rem;
    color: grey
}

.bodyHeader p:hover{
    color: black;
    cursor: pointer;
}

.unlockBar{
    margin: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 30px;
    font-size: .8rem;
    font-weight: bold;
    border: .5px solid red;
    border-radius: 3px;
    background-color: rgb(251,219,221)
}


.chart{
    width: 100px;
    max-width: 1200px;
    background-color: white;
    align-self: center;
    margin-top: 50px;
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    /* justify-content: flex-start; */
    flex-direction: column;
}

.container{
    width: 70%;
    max-width: 1200px;
    background-color: white;
    align-self: center;
    margin-top: 30px;
    border-radius: 8px;
    display: flex;
    /* justify-content: flex-start; */
    flex-direction: column;
}

.container div{
    align-self: center;
    padding: .5rem;
    width: 95%;
    /* background-color:red; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container div span{
    width: 20%;
}

.container div p{
    margin: .5rem
}


.btn_buy{
    width: 50%;
    height: 40px;
    color: white;
    background-color: white;
    /* background-color: rgb(36,36,36); */
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid rgb(36,36,36);
}


.btn_sell{
    width: 50%;
    height: 40px;
    background-color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid rgb(36,36,36);
}
.input{
    height: 35px;
    width: 100%;
    border-radius: 5px;
    border: .5px solid grey;
    outline: none;
    font-weight: bold;
    color: grey;
    text-indent: 10px;
    /* padding-left: 10px; */
}

.container2{
    width: 70%;
    max-width: 1200px;
    background-color: white;
    align-self: center;
    margin-top: 30px;
    border-radius: 8px;
    display: flex;
    /* justify-content: flex-start; */
    flex-direction: column;
}

.container2__header{
    display: flex;
    padding: .5rem;
    align-items :center;
    justify-content: space-between;
}
.container2__body{
    /* background-color: green; */
}
.container2__body div:nth-child(1){
    display: flex;
    border-bottom: .5px solid lightgrey;
    align-items: center;
    margin: 1.25rem;
    justify-content: space-between;
}


.container2__body div p{
    color: grey;
    font-size: .85rem;
    font-weight: bold;
}

.container2__body div:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(230, 227, 227, 0.445);
    margin: 1.25rem;
    height: 60px;
}



.col2::-webkit-scrollbar {
    width: 5px;
}
.col2::-webkit-scrollbar-thumb {
    height: 50px;
    border-radius: 5px;
    background-color: rgb(197, 195, 195);
}
.col2::-webkit-scrollbar-thumb:hover {
    height: 5px;
    border-radius: 5px;
    background-color: rgb(139, 139, 139);
}
.col2::-webkit-scrollbar-track {
    background: transparent;
    border-right: .5px solid lightgray;
}


/* GEARS */
#gear1, #gear2, #gear3 {
    color: #888;
    display: block;
    float: left;
    position: absolute;
}
            
#gear1 {
    top: 45px;
    margin: 100rem;
    position: absolute;
}
            
#gear2 {
    left: 60px;
    margin-bottom: 50px;
}

#gear3 {
    top: 45px;
    left: 110px;
}

.spin {
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}

.spin2 {
    -webkit-animation:spin .5s linear infinite;
    -moz-animation:spin .5s linear infinite;
    animation:spin .5s linear infinite;
}

.spin_back {
    -webkit-animation:spin-back 4s linear infinite;
    -moz-animation:spin-back 4s linear infinite;
    animation:spin-back 4s linear infinite;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@-moz-keyframes spin-back { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes spin-back { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes spin-back { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }
