.main{
    background-color: rgb(243,243,244);
    display: flex;
    flex-direction: column;
    padding-bottom: 4rem;
}

.bodyHeader{
    height: 40px;
    width: auto;
    background-color: white;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    padding-left: 6rem;
    padding-right: 6rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.bodyHeader p{
    margin: .5rem;
    color: grey
}

.stageDiv{
    width: 85%;
    margin-right: auto;
    margin-left: auto;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-bottom: .5px solid lightgrey */
}

.stageDiv h1{
    margin: 1.5rem;
    color: grey
}

/* 
.bodyHeader p:hover{
    color: black;
    cursor: pointer;
} */
.paymentDiv{ 
    width: 40%;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
}

.info{
    width: 100%;
    height: 45px;
    display: flex;
    text-indent: 10px;
    align-items: center;
    background-color: rgb(22,223,181);
}
.next{
    align-self: flex-end;
    width: 100px;
    background-color: blue;
    padding: .5rem;
    border: 0px;
    border-radius: 10px;
    color: white;
    margin-top: 10px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}


.verificationDiv{ 
    width: 50%;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
}

.uploadBtn{
    width: 55%;
    padding: .7rem;
    border-radius: 25px;
    border: 0px;
    font-weight: bold;
    color: white;
    background-color: blue;
    cursor: pointer
}

.docInfo{
    width : 100%
}