.main{
    background-color: rgb(243,243,244);
    display: flex;
    flex-direction: column;
}

.bodyHeader{
    height: 40px;
    width: auto;
    background-color: white;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    padding-left: 6rem;
    padding-right: 6rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.bodyHeader p{
    margin: .5rem;
    color: grey
}

.bodyHeader p:hover{
    color: black; 
    cursor: pointer;
}



.container{
    width: 85%;
    background-color: white;
    align-self: center;
    margin-top: 50px;
    border-radius: 8px;
    display: flex;
    /* justify-content: flex-start; */
    flex-direction: column;
}

.container div{
    margin: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.container div p{
    margin: .5rem
}


.btn_buy{
    width: 80px;
    height: 40px;
    color: white;
    background-color: rgb(36,36,36);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px;
}


.btn_sell{
    width: 80px;
    height: 40px;
    background-color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid rgb(36,36,36);;
}
.input{
    height: 35px;
    width: 180px;
    border-radius: 5px;
    border: .5px solid grey;
    outline: none
}

.container2{
    background-color: white;
    align-self: center;
    margin-top: 50px;
    border-radius: 8px;
    display: flex;
    /* justify-content: flex-start; */
    flex-direction: column;
}

.container2__header{
    border-bottom: .5px solid lightgrey;
    padding: .5rem
}
.container2__body{
    /* background-color: green; */
}

.container2__body div{
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(230, 227, 227, 0.445);
    margin: 1.25rem;
}

.container2__body div img{
    transition: .3s;
    margin: 1rem;
    border-radius: 15px;
}

.container2__body div img:hover{
    transform: scale(1.137);
    transition: .3s;
    cursor: pointer
}


.container3__1{
    align-self: center;
    width: 77%;
    max-width: 1200px;
    border-radius: 8px;
    height: auto;
    margin-top: 30px;
    display: flex;
    background-color: transparent;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
}

.container3__1_child{
    width: 32%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    /* padding: 1rem; */
}

.container3__1_child span{
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    border-bottom: .2px solid rgba(211, 211, 211, 0.973);
}


.container3{
    /* margin-left: auto; */
    /* margin-right: auto; */
    padding: 1rem;
    align-self: center;
    width: 75%;
    max-width: 1200px;
    border-radius: 8px;
    height: auto;
    margin-top: 0px;
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 30px;
}

.container3_child:nth-child(1){
    width: 30%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    /* padding: 1rem; */
}

.container3_child{
    width: 23.3%;
    /* padding: 1rem; */
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
}


.container3_header{
    height: 150px;
    width: 95%;
    margin: .5rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center
}

.container3_header__1{
    height: 150px;
    width: 95%;
    margin: .5rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start
}




.container3_child__body{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container3_child__body__1{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center
}

.container3_child span{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: .2px solid rgba(211, 211, 211, 0.459);
}

.fund_btn{
    background-color: blue;
    margin-left: 15px;
    border: 0px;
    color:white;
    width: 70px;
    height: 25px;
    font-weight: bold;
    font-size: .7rem;
    border-radius: 5px;
}


.fund_btn:hover{
    background-color: rgba(0, 0, 255, 0.637);
    cursor: pointer;
}
