
.footer{
    display: flex;
    width: 100%;
    background-color: rgb(88,65,216);
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 5rem;
}

.footer__col1{
    display: flex;
    width: 75%;
    max-width: 1200px;
    background-color: rgb(88,65,216);
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    padding-bottom: 2rem;
}
.footer__col1 div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width:700px){
    .footer__col1{
        flex-direction: column;
        align-items: center;
        padding-left: 0rem;
        padding-right: 0rem;
    }

}