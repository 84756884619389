.main{
    background-color: rgb(243,243,244);
    display: flex;
    flex-direction: column;
}

.bodyHeader{
    height: 40px;
    width: auto;
    background-color: white;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    padding-left: 6rem;
    padding-right: 6rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.bodyHeader span{
    margin: .5rem;
    color: grey;
    font-size: .85rem;
}

.bodyHeader span:hover{
    color: black;
    cursor: pointer;
}



.container{
    width: 85%;
    background-color: white;
    align-self: center;
    margin-top: 50px;
    border-radius: 8px;
    display: flex;
    /* justify-content: flex-start; */
    flex-direction: column;
}

.container div{
    margin: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.container div p{
    margin: .5rem
}


.btn_buy{
    width: 80px;
    height: 40px;
    color: white;
    background-color: rgb(36,36,36);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px;
}


.btn_sell{
    width: 80px;
    height: 40px;
    background-color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid rgb(36,36,36);;
}
.input{
    height: 35px;
    width: 180px;
    border-radius: 5px;
    border: .5px solid grey;
    outline: none
}

.container2{
    width: 42%;
    background-color: white;
    align-self: center;
    margin-top: 50px;
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    /* justify-content: flex-start; */
    flex-direction: column;
    padding-bottom: 20px;
}


.container2 div{
    display: flex;
    border-bottom: .5px solid rgba(211, 211, 211, 0.521);
    align-items: center;
    /* background-color: green; */
    /* margin: 1.25rem; */
    justify-content: space-between;
}

.update_btn{
    padding: .75rem;
    background-color: rgb(40,36,182);
    width: 95%;
    color: white;
    border-radius: 20px;
    border: 0px;
    align-self: center;
    font-weight: bold;
    font-size: 1.1rem;
}


.account_types{
    /* margin-left: auto; */
    /* margin-right: auto; */
    padding: 1rem;
    align-self: center;
    width: 75%;
    max-width: 1200px;
    border-radius: 8px;
    height: auto;
    margin-top: 50px;
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 30px;
}

.account_type_child:nth-child(1){
    width: 40%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    /* padding: 1rem; */
}

.account_type_child{
    width: 20%;
    /* padding: 1rem; */
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
}


.account_type_header{
    height: 150px;
    width: 95%;
    margin: .5rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center
}

.account_type_header__1{
    height: 150px;
    width: 95%;
    margin: .5rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start
}




.account_type_child__body{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.account_type_child__body__1{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center
}

.account_type_child span{
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    border-bottom: .2px solid rgba(211, 211, 211, 0.459);
}

.choose_account_btn{
    border: 0px;
    width: 80%;
    height: 40px;
    color: rgba(19, 18, 18, 0.527);
    font-weight: bold;
    border-radius: 5px;
    background-color: white;
}

.choose_account_btn:hover{
    color: black;
    cursor: pointer;
}