.main {
    width: auto;
    height: 80px;
    background-color: rgb(88,65,216);
    display: flex;
    align-items: 'center';
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 2rem;
}


.nav__items{
    display: flex;
    align-items: center;
}

.main div p{
    margin: 1rem
}

.img{
    width: 12%
}


@media only screen and (max-width:700px){
    .main{
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .logo_text{
        display: none
    }

    .img{
        width: 45%;
    }
    /* .nav__items{
        display: none
    } */
}
@media only screen and (min-width: 700px) and  (max-width: 1024px){

}