.main{
    width: 100vw;
    height: 100vh;
    background: url(../../images/signup-back.svg);
    /* background-size: 100vw 100vh */
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main nav{
    height: 100px;
    background: red;
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.main form{
    display: flex;
    flex-direction: column;
    width: 40%;
    border-radius: 20px;
    height: auto;
    padding: 20px;
    background-color: white;
}

.signin_btn{
    background-color: transparent;
    align-self: flex-end;
    border-radius: 50px;
    font-weight: bold;
    color: rgb(88,65,216);
    margin: .5rem
}
.create_btn{
    padding: .8rem;
    background-color: rgb(88,65,216);
    border: 0px;
    width: 200px;
    border-radius: 50px;
    font-weight: bold;
    color: white
}

@media only screen and (max-width:700px){
    .main{
        background: white;
    }
    .main form{
        width: 90%;
    }
    img{
        width: 20%
    }
}

@media only screen and (min-width: 700px) and  (max-width: 1024px){

}
