.main{
    background-color: rgb(243,243,244);
    display: flex;
    flex-direction: column;
    padding-bottom: 4rem;
}

.bodyHeader{
    height: 40px;
    width: auto;
    background-color: white;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    padding-left: 6rem;
    padding-right: 6rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.bodyHeader p{
    margin: .5rem;
    color: grey
}

.bodyHeader p:hover{
    color: black;
    cursor: pointer;
}



.container{
    width: 85%;
    background-color: rgb(247,246,253);
    align-self: center;
    margin-top: 50px;
    border-radius: 8px;
    display: flex;
    border: 1px solid rgb(93,69,220);
    color: rgb(93,69,220);
    /* justify-content: flex-start; */
    flex-direction: column;
}

.container div:nth-child(1){
    margin-right: 2rem;
    margin-left: 2rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.container div h3{
    margin: 0;
    font-size: 3vw

}

.container div p{
    font-size: 1vw
}

.container div:nth-child(2){
    margin-right: 2rem;
    margin-left: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}





.upgrade_btn{
    padding: .5rem;
    border: 1px solid rgb(93,69,220);
    font-weight: bold;
    color:rgb(93,69,220);
    background-color: white;
    margin-bottom: 2rem;
    border-radius: 5px;
    font-size: 1vw;
    /* cursor: not-allowed */
}


.btn_buy{
    width: 80px;
    height: 40px;
    color: white;
    background-color: rgb(36,36,36);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px;
}


.btn_sell{
    width: 80px;
    height: 40px;
    background-color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid rgb(36,36,36);;
}
.input{
    height: 35px;
    width: 180px;
    border-radius: 5px;
    border: .5px solid grey;
    outline: none
}