.div_one{
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
    justify-content: space-around;
    display: flex;
    align-items: center;
    /* background-color: rgb(239,236,251); */
    background-image: url('../../images/back.webp');
    align-self: center;
    background-size: 100vw 110vh;
}
.krkn_vid{
    width: 20%;
}
.main_text{
    font-size: 4rem;
}
.main_text2{
    font-size: 2rem;
    line-height: '3rem'
}

.div_two{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}
.div_two__child{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.div_two__child div{
    display: flex;
    flex-direction: row;
    align-items:center;
    box-shadow: 1px 1px 5px lightgrey;
    padding:1rem;
    margin-bottom:1rem;
    border-radius: 2rem;
}
.div_two__child div p{
    margin: 1rem
}

.div_three{
    display: flex;
    width: 100%;
    background-color: rgb(88,65,216);
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.div_three div{
    width: auto
}
.div_three p{
    color: white
}

.div_four{
    display: flex;
    width: 100%;
    background-color: rgb(255,139,139);
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.div_four p{
    color: white
}


.div_five{
    display: flex;
    width: 70%;
    background-color: white;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: flex-start;
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.div_five p{
}
.div_five__heading{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.div_five__heading h1{
    color: rgb(88,65,216);
}

.div_five__cards{ /* cards container */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.div_five__card{ /* card */
    width: 25%;
    display: flex;
    min-height: 400px;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    padding: .5rem; 
    transition: .5s;
    cursor: pointer;
    background-color: #202333;
    box-shadow: 1px 1px 20px rgba(189, 186, 186, 0.856);
}

.div_five__card:hover{
    transform: scale(1.12);
    transition: .5s;
}

.div_five__card p:nth-child(1){
    font-size: 27px;
    font-weight: bolder;
}

.div_five__card p{
    color: white;
    font-size: 1rem;
    text-align: center;
    /* line-height: 23px; */
}

.div_six{
    display: flex;
    width: 100%;
    background-color: rgb(190,228,252);
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    justify-content: flex-start;
    padding-bottom: 5rem;
    color: white
}

.div_six div{
    display: flex;
    justify-content: center;
    align-items: center;
}


.div_seven{
    display: flex;
    width: 100%;
    background-color: rgb(254,181,141);
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.div_seven p{
    color: white
}
.img{
    width: 35%
}
@media only screen and (max-width:700px){
    .div_one{
        flex-direction: column;
        background-color: rgb(239,236,251);
        background-size: 100vw 20%;
        background-image: url('../../images/back-inv.webp');
        background-repeat: no-repeat;
    }
    .krkn_vid{
        width: 70%;
    }
    .main_text{
        font-size: 2.3rem;
    }
    .main_text2{
        font-size: 1.2rem;
    }

    .div_one div{
        width: 80%
    }
    .div_two{
        flex-direction: column;
    }
    .div_two div{
        width: 80%
    }
    .div_two__child div{
        flex-direction: column;
    }

    .div_three{
        flex-direction: column;
    } 
    .div_three div{
        width: 80%
    }

    .div_four{
        flex-direction: column;
    }
    .div_four div{
        width: 80%
    }
    /* .div_five__col1{
        flex-direction: column;
        align-items: center;
        padding-left: 0rem;
        padding-right: 0rem;
    } */
    .div_five{
        flex-direction: column;
        width: 90%;
    }
    .div_five__cards{
        flex-direction: column;
    }
    .div_five__card{
        width: 100%;
        margin-bottom: 20px;
        height: auto;
    }
    .div_five__heading h1{
        text-align: center;
    }

    .div_five__heading p{
        text-align: center;
    }

    .div_seven{
        flex-direction: column-reverse;
    }
    .div_seven div{
        width: 80%
    }
    .img{
        width: 80%
    }

}
@media only screen and (min-width: 700px) and  (max-width: 1024px){

}
