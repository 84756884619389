.main{
    width: 100%;
    height: auto;
}

.users__header{
    background-color: lightgray;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.users{
    display: flex;
    text-align: center;
    align-items: center;
    border-bottom: .5px solid lightgray;
    justify-content: space-around;
}