.nav_one{
    height: 60px;
    background-color: rgb(40,36,182);
    /* padding-left: 5vw; */
    /* padding-right: 5vw; */
    width: 100%;
    /* max-width: 1200px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav_one__main{
    width: 85%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav_one div{
    display: flex;
    align-items: center;
    margin: 0
}
.nav_one div p{
    color: white;
    font-size: .95rem;
    padding: .9rem;
    border-bottom: 4px solid transparent;
    margin: 0
}
.buy_crypto_btn{
    background-color:  white;
    border-radius: 5px;
    height: 30px;
    cursor: pointer;
    width: 100px;
    color: rgb(88,65,216);
    font-weight: bold;
    border: 0px

}
.nav_one div p:hover{
    border-bottom: 4px solid rgba(211, 211, 211, 0.329);
    cursor: pointer;
}

.nav_two{
    height: 60px;
    background-color: rgb(36,36,36);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.nav_two__main{
    width: 85%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}


.nav_two p{
    margin: 1.5rem
}

